import 'twin.macro';
import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Button } from './atoms';
import config from '../utils/config';

const Header = () => (
  <Popover tw="bg-transparent shadow-3xl">
    {({ open }) => (
      <>
        <div tw="mx-auto container px-4">
          <div tw="flex justify-between items-center py-2 md:justify-start md:space-x-10">
            <div tw="flex justify-start">
              <Link to="/">
                <span tw="sr-only">{config.siteName}</span>
                <img
                  tw="h-8 w-auto sm:h-16"
                  src="/images/profield-logo.png"
                  alt={config.siteName}
                />
              </Link>
            </div>
            <div tw="-mr-2 -my-2 md:hidden">
              <Popover.Button tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:text-primary hover:bg-gray-100 focus:outline-none">
                <span tw="sr-only">Open menu</span>
                <MenuIcon tw="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group
              as="nav"
              tw="hidden md:flex justify-end lg:space-x-8 space-x-4 flex-auto text-sm text-primary"
            >
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/services">Services</Link>
              <Link to="/#works">Works</Link>
              <Link to="/startso-app-store">Apps</Link>
              <Link to="/products">Products</Link>
              <Link to="/contact">Contact</Link>
            </Popover.Group>
            <div tw="hidden md:block">
              <Link to="/request-quote">
                <Button>
                  <span tw="flex items-center">GET A QUOTE </span>
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <Transition
          show={open}
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            static
            tw="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 z-50">
              <div tw="pt-5 pb-6 px-5">
                <div tw="flex items-center justify-between">
                  <div>
                    <img
                      tw="h-8 w-auto"
                      src="/images/profield-logo.png"
                      alt={config.siteName}
                    />
                  </div>
                  <div tw="-mr-2">
                    <Popover.Button tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:text-primary hover:bg-gray-100 focus:outline-none focus:border">
                      <span tw="sr-only">Close menu</span>
                      <XIcon tw="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div tw="pt-6 px-5 pb-10 space-y-6">
                <div tw="grid grid-cols-2 gap-y-4 gap-x-8 text-primary">
                  <Link to="/">Home</Link>
                  <Link to="/about">About</Link>
                  <Link to="/services">Services</Link>
                  <Link to="/#works">Works</Link>
                  <Link to="/startso-app-store">Download Apps</Link>
                  <Link to="/products">Products</Link>
                  <Link to="/contact">Contact</Link>
                  <Link to="/request-quote">
                    <Button>
                      <span tw="flex items-center">GET A QUOTE </span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default Header;
