import tw from 'twin.macro';
import { createGlobalStyle } from 'styled-components';

const StylesBase = createGlobalStyle`
  .light {
    --bg-primary: #FFFFFF;
    --bg-secondary: #E5E5E5;
    --text-primary: #252B42;
    --text-secondary: #696969;
    --color-primary: #ff414b;
    --color-light: #FFF2F3;
  }
  .dark {
    --bg-primary: #0F172A;
    --bg-secondary: #1E293B;
    --text-primary: #CBD5E1;
    --text-secondary: #FFFFFF;
    --color-primary: #2563EB;
  }
  body {
    ${tw`bg-white text-primary transition-all duration-200 font-poppins`}
  }
  .slick-dots {
    bottom: -45px !important
  }
  ${
    '' /* li ::marker {
    color: var(--color-primary);
  } */
  }
`;

export default StylesBase;
