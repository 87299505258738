import tw from 'twin.macro';
import React from 'react';

const Heading = ({ children, ...props }) => {
  if (props.isLarge || props.extraLarge)
    return (
      <h1
        css={[
          tw`text-5xl text-primary font-semibold md:leading-snug`,
          props.hasTextWhite && tw`text-white`,
          props.extraLarge && tw`text-6xl font-bold`,
          props.fontNormal && tw`text-6xl font-medium`,
        ]}
      >
        {children}
      </h1>
    );

  return (
    <h3
      css={[
        tw`md:text-5xl text-4xl font-normal text-primary md:leading-tight my-2`,
        props.hasTextWhite && tw`text-white`,
        props.fontMedium && tw`md:text-4xl text-4xl`,
        props.fontBold && tw`font-bold`,
      ]}
    >
      {children}
    </h3>
  );
};
export default Heading;
