import tw from 'twin.macro';
import React from 'react';

const Subtitle = ({ children, fontNormal, fontQuote }) => (
  <h6
    css={[
      tw`text-xl font-semibold text-primary`,
      fontNormal && tw`font-normal text-lg`,
      fontQuote && tw`text-secondary text-xl`,
    ]}
  >
    {children}
  </h6>
);
export default Subtitle;
