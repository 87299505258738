module.exports = {
  DEBUG: process.env.NODE_ENV === 'development',
  siteName: 'ProField',
  author: 'Vishal Choudhary',
  description:
    'Startso Software Solutions is an eminent mobile/web app development company & the best IT Software Solutions provider based in Bangalore, India, established in 2021.',
  siteUrl: 'https://startso.in',
  logo: '/images/profield-logo.png',

  telephone: '+91-8618270609',
  email: 'info-support@startso.in',
  location: 'Bangalore, India',
  twitter: '',
  instagram: 'https://www.instagram.com/startso.in/',
  facebook: 'https://www.facebook.com/startso2021',
  sanityId: '',
  type: 'website',
  googleAnalytics: 'UA-217391955-1',
  googleAdsId: 'AW-10840684616',
  facebookPixelId: '1641760229501441',
  backgroundColor: '#ffffff',
  themeColor: '#ED1C24',
  SEND_IN_BLUE_API_KEY:
    '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',
  productsData: [
    {
      id: 1,
      slug: 'qrcode-based-solution',
      image: '/images/upanal/main.jpg',
      logo: '/images/logo.png',
      tags: ['Android', 'IOS', 'Node Js', 'React js'],
      title: 'QRcode based Smart CRM solution',
      name: 'A QRCODE BASED SMART CRM SOLUTION',
      heroImage: '/images/upanal/hero.png',
      overView: {
        image: '/images/upanal/main.jpg',
        details:
          'A Smart solution to serve the customers by providing high quality service, improve sales and scale their business. Customisable workflows for managing the field resources to increase productivity. It has been started with a promise that "products supplied by us will provide a greater value than the price paid for it & service is the part of product". This principle represents our day-to-day commitment to our customers. Our outstanding customer support & technical support with variety of operational benefits designed to save your time and money. By doing so, we feel proud of the way we do business.',
      },
      productComponents: [
        {
          id: '1',
          blackHeading: 'Start',
          redHeading: 'So',
          title: ' - Admin Dashboard',
          details:
            'A web application platform for businesses to manage field resources, customer management, resource management and daily track of work with simple & smart workflows, Interactive analytic insights & reports.',
        },
        {
          id: '2',
          blackHeading: 'Pro',
          redHeading: 'Field',
          title: ' - for field resources',
          details:
            'A mobile app for field resources – to perform & manage daily activities like Sales/service/AMC/Breakdown activities with smart workflows, Attendance management, claim/expense management. Get critical alerts, push notifications, whatsapp & telegram notifications.',
        },
        {
          id: '3',
          blackHeading: 'Start',
          redHeading: 'Scan',
          title: ' - for end users',
          details:
            'A mobile app for end users- register and raise service/AMC/Breakdown requests, get real time progress notification on the request, view history of all the requests, view & buy/upgrade products with exchange benefits.',
        },
        {
          id: '4',
          blackHeading: 'mStart',
          redHeading: 'Scan',
          title: ' - for users without app',
          details:
            'A QRCode based web solution for end users to raise service/AMC/Breakdown requests, get real time progress notification on the request',
        },
      ],
      dashboardFeatures: [
        {
          id: 1,
          image: '/images/upanal/admin-1.png',
          features: [
            {
              id: '1',
              title: 'Dashboard',
              details: [
                'Admin has a dashboard that contains different chart wise data.',
                'A platform for business to manage field resources, customer management, resource management and daily track of work with simple & smart workflow',
                'Interactive analytics insights & reports',
                'Sales / Service / Breakdown / Payments / AMC Management,  Attendance management, Generate QR Code, Smart Location, Claims & expense management.',
              ],
            },
          ],
        },

        {
          id: 3,
          image: '/images/upanal/admin-4.png',
          features: [
            {
              id: '1',
              title: 'QR Code',
              details: [
                'Admin can create a product-wise QR code in batch. User just scan QR code to raise a request.',
              ],
            },
            {
              id: '2',
              title: 'Notifications',
              details: [
                'Get notification on the request progress',
                'Push notifications, whatsapp & telegram notifications',
              ],
            },
          ],
        },
        {
          id: 4,
          image: '/images/upanal/admin-5.png',
          features: [
            {
              id: '1',
              title: 'Employee Management',
              details: [
                'Admin can have all employees details with filtration options.',
                'Admin can add, edit, and view employee details.',
              ],
            },
            {
              id: '2',
              title: 'Attendance',
              details: [
                'Admin can check daily attendance of employees.',
                'Admin can check past attendance of employees via select date option.',
              ],
            },
          ],
        },
      ],
      appFeatures: [
        {
          id: 1,
          image: '/images/upanal/app-1.png',
          groupOneFeatures: [
            {
              id: '2',
              title: 'Overview',
              details: [
                'User can see their progress report.',
                'User can see their monthly report according to date.',
                'User can see their tasks report according to status (Completed, Assigned, Pending, Accepted, Overdue).',
                'User can manage their profile',
              ],
            },
          ],
          groupTwoFeatures: [
            {
              id: '1',
              title: 'Pro Fields',
              details: [
                'Manage daily activities like Sales / Service / Breakdown  / AMC activities with smart workflows.',
                'Attendance management, Claims/expense management',
              ],
            },
            {
              id: '2',
              title: 'Notifications',
              details: [
                'Get critical alerts, push notifications, whatsapp & telegram notifications.',
              ],
            },
          ],
        },
        {
          id: 2,
          image: '/images/upanal/app-2.png',
          groupOneFeatures: [
            {
              id: '1',
              title: 'Start',
              redText: 'Scan',
              details: [
                'Mobile app user raise Service / AMC / Breakdown requests.',
                'Get real time progress notifications on the request.',
                'View history of all the requests.',
                'View & Buy/Upgrade product with exchange benefits.',
                'Digital signoff & reports.',
                'Manage of machines/appliances.',
              ],
            },
          ],
          groupTwoFeatures: [
            {
              id: '1',
              title: 'M-Start',
              redText: 'Scan',
              details: [
                'A QR code based web solution for end user to raise Service / AMC / Breakdown requests.',
                'Just Scan QR code to raise a request.',
                'Get real time progress notifications on the request.',
              ],
            },
          ],
        },
      ],
    },
  ],
};
