import 'twin.macro';
// import { MenuIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'gatsby';

import config from '../utils/config';
import SocialIcons from './SocialIcons';

const Footer = () => (
  <footer tw="relative bg-secondary pt-8 pb-6 text-primary">
    <div tw="xl:px-40 lg:px-20 md:px-10 sm:px-5 px-10">
      <div tw="w-full pt-12 flex flex-col sm:flex-row space-y-2  justify-start">
        <div tw="w-full sm:w-2/5 pr-6 flex flex-col space-y-4 mt-2">
          <h3 tw="text-primary font-bold mb-0">Contact Details</h3>
          <p tw="opacity-90">{config.location}</p>
          <a tw="opacity-90" href={`mailto:${config.email}`}>
            {config.email}
          </a>
          <a tw="opacity-90" href={`tel:${config.telephone}`}>
            {config.telephone}
          </a>
          <p>
            © 2021{' '}
            <a target="_blank" href="https://www.profield.in/" rel="noreferrer">
              {config.siteName}
            </a>
          </p>
        </div>
        <div tw="w-full sm:w-1/5 flex flex-col space-y-4 ">
          <h3 tw="text-primary font-bold mb-0">Quick Links</h3>
          <Link to="#services" tw="opacity-90">
            Services
          </Link>
          <Link to="/#works" tw="opacity-90">
            Works
          </Link>
          <Link to="/startso-app-store" tw="opacity-90">
            Download Apps
          </Link>
          <Link to="/products" tw="opacity-90">
            Products
          </Link>
        </div>

        <div tw="w-full sm:w-1/5 flex flex-col space-y-4 ">
          <h3 tw="text-primary font-bold mb-0">Quick Links</h3>
          <Link to="/" tw="opacity-90">
            Home
          </Link>
          <Link to="/about" tw="opacity-90">
            About
          </Link>
          <Link to="/request-quote" tw="opacity-90">
            Get a quote
          </Link>
          <Link to="/contact" tw="opacity-90">
            Contact
          </Link>
        </div>

        <div tw="w-full sm:w-1/5 pt-6 flex items-end mb-1">
          <SocialIcons />
        </div>
      </div>
      <div tw="border-t border-gray-400 mt-8 pt-4">
        <span tw="flex items-center justify-center">
          A product of Start<span tw="text-secondary">SO</span>
          <img tw="w-14 ml-2" src="/images/logo.png" alt={config.siteName} />
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;
