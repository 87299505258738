import tw from 'twin.macro';
import React from 'react';

const Button = ({ children, isFullWidth, ...otherProps }) => (
  <button
    type="button"
    css={[
      tw`py-3 px-5 rounded-md border border-primary bg-tertiary font-bold text-white text-sm transition delay-150 hover:bg-transparent hover:text-primary hover:border hover:border-secondary focus:border focus:border-secondary`,
      isFullWidth && tw`w-full`,
    ]}
    {...otherProps}
  >
    {children}
  </button>
);
export default Button;
