import 'twin.macro';
import React from 'react';
import config from '../utils/config';

const SocialIcons = () => {
  const socialItems = [
    { id: 1, icon: 'fab fa-twitter', url: config.twitter },
    { id: 2, icon: 'fab fa-facebook-f', url: config.facebook },
    { id: 3, icon: 'fab fa-instagram', url: config.instagram },
    { id: 4, icon: 'fab fa-pinterest-p', url: config.pinterest },
  ];
  return (
    <div tw="flex flex-row space-x-6">
      {socialItems.map((item) => {
        if (!item.url) {
          return null;
        }
        return (
          <a
            key={item.id}
            target="_blank"
            href={item.url}
            tw="bg-tertiary	text-white shadow-lg h-10 w-10 rounded-full py-2 text-center"
            rel="noreferrer"
          >
            <i className={item.icon} />
          </a>
        );
      })}
    </div>
  );
};
export default SocialIcons;
