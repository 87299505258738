import tw from 'twin.macro';
import React from 'react';

const Paragraph = ({ children, hasTextWhite, textSmall }) => (
  <p
    css={[
      tw`text-base text-tertiary my-3`,
      hasTextWhite && tw`text-white`,
      textSmall && tw`text-sm`,
    ]}
  >
    {children}
  </p>
);
export default Paragraph;
